<footer id="footer" style="background-color: #195e36;">
  <!--Container-->
  <div class="container spacer-double-sm" style="background-color: #195e36;">
    <!--Row-->
    <div class="row mb-5">
      <div class="col-md-4 mb-5 mb-md-0">
        <div class="media">
          <img
            class="max-width-sm mr-3"
            src="assets/svg/contact-icon.svg"
            alt=""
          />
          <div class="media-body">
            <h4 class="text-white h6 mb-0">Call us/ Whats app</h4>
            <span class="d-block text-light font-size-15"
              ><a href="whatsapp://send/?phone=+201227434217&text=EGSCOE 2023">+201 227 434 217</a>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-5 mb-md-0">
        <div class="media">
          <img
            class="max-width-sm mr-3"
            src="assets/svg/email-icon.svg"
            alt=""
          />
          <div class="media-body">
            <h4 class="text-white h6 mb-0">Email us</h4>
            <span class="d-block text-light font-size-15"
              ><a href="mailto:mohamed@continental.com.eg">mohamed@continental.com.eg</a></span
            >
          </div>
        </div>
      </div>
         <div class="col-md-4 mb-5 mb-md-0">
        <div class="media">
          <img
            class="max-width-sm mr-3"
            src="assets/svg/ticket-icon.svg"
            alt=""
          />
          <div class="media-body">
            <h4 class="text-white h6 mb-0"></h4>
            <span class="d-block text-light font-size-15"
              ><button type="button"  class="btn  "  style="color: #cdcf35" data-mdb-ripple-color="dark"><a  style="color:white"href="mailto:mohamed@continental.com.eg?subject=EGSCOE 2023 Registration &body=Name:%0D%0AEmail:%0D%0APhone:%0D%0AWorkplace:">Submit Registration Request</a></button></span
            >
          </div>
        </div>
      </div>
      <!--<div class="col-md-4 mb-5 mb-md-0">
        <div class="media">
          <img
            class="max-width-sm mr-3"
            src="assets/svg/location-icon.svg"
            alt=""
          />
          <div class="media-body">
            <h4 class="text-white h6 mb-0">Address</h4>
            <span class="d-block text-light font-size-15"
              >213, 4th Floor, R364 Building, Al Wasl Properties, Al Karama, Dubai, U.A.E.
            </span>
          </div>
        </div>
      </div>-->
    </div>
    <!--End row-->
    <!--Row-->
    <!-- <div class="row justify-content-center">
      <div class="col-auto">
        <ul class="list-inline mb-2 mt-3">
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-facebook-f"></span
            ></a>
          </li>
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-twitter"></span
            ></a>
          </li>
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-instagram"></span
            ></a>
          </li>
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-twitter"></span
            ></a>
          </li>
        </ul>
      </div>
    </div> -->
    <!--End row-->
    <!--Row-->
    <div class="row justify-content-center">
      <div class="col-auto">
        <p class="font-size-14 text-muted text-center mb-5">
          &copy; 2022 all rights reserved - 
        
          Powered by
          <a
            class="text-light"
            target="_blank"
            href="http://primesolutionsweb.com"
            >Prime Solutions.</a
          >.
        </p>
      </div>
    </div>
    <!--End row-->
  </div>
  <!--End containers-->
</footer>
