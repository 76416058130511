<section id="accomodation" style="background-color: #ffffff">
  <!--Conatiner-->
  <div class="container container-md">
    <!--Row-->
    <div class="row ">
      <div class="col">
        <div class="mb-5 pb-5 text-center">
          <span class="icon-title icon-bg-dark rounded-circle p-2 mb-3">
            <img
              class="icon-title-inner"
              src="assets/svg/hotel-icon.svg"
              alt=""
            />
          </span>
          <h2 class="h1">Hotel Accomodation</h2>
          <!-- <p class="w-md-60 mx-auto mb-0 lead">
            We are very excited about the program being planned for Events 2019
            this summer in Cincinati, Ohio.
          </p> -->
        </div>
      </div>
    </div>
    <!--End row-->
    <!--Row-->
    <div class="row  justify-content-center ">
      <div class="text-center">
        <table class="table table-bordered table-striped table-responsive-sm table-sm">
          <thead class="thead-dark">
            <tr>
              <th scope="col">1</th>
              <th scope="col" colspan="3"><strong>Conrad Abu Dhabi, Etihad Towers</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row"></th>
              <td colspan="2">Occupancy</td>
              <td>Contact: Samantha Weston</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td><strong>Single</strong></td>
              <td><strong>Double</strong></td>
              <td>+971 55 500 8840</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>800 AED</td>
              <td>850 AED</td>
              <td>Samantha.Weston@conradhotels.com</td>
            </tr>
          </tbody>
          <thead class="thead-dark">
            <tr>
              <th scope="col">2</th>
              <th scope="col" colspan="3"><strong>Grand Hyatt Abu Dhabi</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row"></th>
              <td colspan="2">Occupancy</td>
              <td>Contact: Hesham Said</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td><strong>Single</strong></td>
              <td><strong>Double</strong></td>
              <td>+971 56 682 9133</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>600 AED</td>
              <td>650 AED</td>
              <td>hesham.said@hyatt.com</td>
            </tr>
          </tbody>

          <thead class="thead-dark">
            <tr>
              <th scope="col">3</th>
              <th scope="col" colspan="3"><strong>Khalidiya Palace Rayhaan by Rotana, Abu Dhabi</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row"></th>
              <td colspan="2">Occupancy</td>
              <td>Contact: Sami Amro</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td><strong>Single</strong></td>
              <td><strong>Double</strong></td>
              <td>+971 50 642 0527</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>580 AED</td>
              <td>643 AED</td>
              <td class="">sami.amro@rotana.com</td>
            </tr>
          </tbody>

          <thead class="thead-dark">
            <tr>
              <th scope="col">4</th>
              <th scope="col" colspan="3"><strong>Bab Al Qasr Hotel & Residences, Abu Dhabi</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row"></th>
              <td colspan="2">Occupancy</td>
              <td>Contact: Mohammed Ismail</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td><strong>Single</strong></td>
              <td><strong>Double</strong></td>
              <td>+971 56 505 2175</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>600 AED</td>
              <td>650 AED</td>
              <td>Mohammed.ismail@babalqasr.com</td>
            </tr>
          </tbody>


      
          
        </table>
        
      </div>
    </div>

    <!--End row-->
  </div>
  <br /><br /><br />
  <!-- <div class="curved-decoration">
    <svg
      version="1.1"
      class="bg-white-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
      <polygon points="2560,221 0,221 2560,0 "></polygon>
    </svg>
  </div> -->
  <div class="curved-decoration img-decoration img-decoration-bottom">
    <svg
      version="1.1"
      class="bg-secondary-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
      <polygon points="2560,221 0,221 2560,0 "></polygon>
    </svg>
  </div>
</section>
